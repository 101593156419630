import { Link } from "gatsby"
import React from "react"
import Headroom from "react-headroom"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FaCaretDown } from "react-icons/fa"
import BurgerMenu from "../components/Burger"
import OperationsSubMenu from "../components/operationsSubMenu"
import MoreSubMenu from "../components/moreSubMenu"
import ImageLogoDomeInvalides from "./imageLogoDomeInvalides"
import { FaPhone } from "react-icons/fa"
import { MdLocationOn } from "react-icons/md"

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { toggled: false, active: false }
    this.handler = this.handler.bind(this)
    this.handlerSubMenu = this.handlerSubMenu.bind(this)
  }

  // For the burger meny
  handler() {
    this.setState(prevState => ({
      toggled: !prevState.toggled,
    }))
    if (this.state.active === true) {
      this.setState({ active: false })
    }
  }

  // for the opérations submeny
  handlerSubMenu() {
    this.setState(prevState => ({
      active: !prevState.active,
    }))
  }

  render() {
    return (
      <header
        css={css`
          background-color: white;
          border-bottom: 1px solid rgba(0, 0, 0, 0.0975);
          width: 100%;
          position: relative;
          z-index: 2;
        `}
      >
        <Headroom
          css={css`
            height: 84px !important;
          `}
          className={this.state.toggled ? "toggled" : "desktop"}
        >
          <Nav>
            <LogoWrapper>
              <Link
                to="/"
                css={css`
                  text-decoration: none;
                `}
              >
                <ImageLogoDomeInvalides />
                <Logo>Dr Philippe Roure</Logo>
                <SubTitle>Chirurgien orthopédiste à Paris</SubTitle>
              </Link>
            </LogoWrapper>
            <BurgerMenu handler={this.handler} />
            <Ul>
              <Li>
                <StyledLink
                  to="/chirurgien-main-coude-epaule-paris/"
                  activeClassName="active"
                >
                  Présentation
                </StyledLink>
              </Li>
              <Li
                className={this.state.active ? "active" : "inactive"}
                onClick={this.handlerSubMenu}
                css={css`
                  .operation-submenu {
                    display: none;
                  }
                  .submenu {
                    display: none;
                  }
                  :hover,
                  :focus {
                    .submenu {
                      display: block;
                    }
                    .operation-submenu {
                      display: block;
                      opacity: 1;
                    }
                  }
                  @media (max-width: 1050px) {
                    .operation-submenu {
                      display: block;
                      top: 80px;
                      height: 1000px;
                      left: 100vw;
                      opacity: 1;
                    }
                  }
                `}
              >
                Opérations
                <FaCaretDown
                  css={css`
                    font-size: 13px;
                    padding-left: 3px;
                    @media (max-width: 1050px) {
                      font-size: 20px;
                      transform: rotate(-90deg) translateX(-6px);
                    }
                  `}
                />
                <OperationsSubMenu />
              </Li>
              <Li>
                <StyledLink to="/consultation/" activeClassName="active">
                  Consultation
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to="/hospitalisation/" activeClassName="active">
                  Hospitalisation
                </StyledLink>
              </Li>
              <Li>
                <StyledLink to="/equipe/" activeClassName="active">
                  L'Équipe
                </StyledLink>
              </Li>
              <Li
                css={css`
                  position: relative;
                  .submenu {
                    display: none;
                  }
                  :hover,
                  :focus {
                    .submenu {
                      display: block;
                    }
                  }
                  @media (max-width: 1050px) {
                    span {
                      display: none;
                    }
                    .submenu {
                      display: block;
                    }
                  }
                `}
              >
                <span>À Propos</span>
                <FaCaretDown
                  css={css`
                    font-size: 13px;
                    padding-left: 3px;
                    @media (max-width: 1050px) {
                      display: none;
                    }
                  `}
                />
                <MoreSubMenu />
              </Li>
              <Li
                css={css`
                  @media (max-width: 1050px) {
                    display: none;
                  }
                `}
              >
                <StyledLink to="/contact/" activeClassName="active">
                  Contact
                </StyledLink>
              </Li>
              <Li
                css={css`
                  margin-top: -15px;
                  svg {
                    margin-right: 4px;
                  }
                  @media (min-width: 1050px) {
                    display: none;
                  }
                `}
              >
                {" "}
                <StyledLink to="/contact/" activeClassName="active">
                  <MdLocationOn />
                  Contact & Accès
                </StyledLink>
              </Li>
              <Li
                css={css`
                  svg {
                    margin-right: 5px;
                  }
                  a {
                    text-decoration: none;
                  }
                  @media (min-width: 1050px) {
                    display: none;
                  }
                `}
              >
                <a href="tel:+33153598809">
                  <FaPhone />
                  Appelez le cabinet
                </a>
              </Li>
              <Li
                css={css`
                  @media (min-width: 1050px) {
                    display: none;
                  }
                `}
              >
                <StyledLink to={this.props.pathEn}>
                  <span role="img" aria-label="English version">
                    🇬🇧
                  </span>{" "}
                  English version
                </StyledLink>
              </Li>
            </Ul>
            <Link
              to={this.props.pathEn}
              css={css`
                position: absolute;
                right: 0;
                border-bottom: none;
                text-decoration: none;
                font-size: 0.9em;
                letter-spacing: -0.02em;
                font-weight: 600;
                @media (max-width: 1050px) {
                  display: none;
                }
              `}
            >
              <span role="img" aria-label="English version">
                🇬🇧
              </span>
              <div
                css={css`
                  display: inline-block;
                  font-size: 0.78em;
                  color: #4f5767;
                  padding-left: 2px;
                  transform: translateY(-0.08em);
                `}
              >
                English
              </div>
            </Link>
          </Nav>
        </Headroom>
      </header>
    )
  }
}

const Nav = styled.nav`
  position: relative;
  width: 1200px;
  max-width: 100%;
  display: flex;
  background-color: white;
  padding: 2px 0px 0px;
  margin: 0px auto;
  height: 82px;
  @media (max-width: 1050px) {
    display: block;
  }
`

const LogoWrapper = styled.div`
  z-index: 1;
  margin-right: auto;
  padding-top: 5px;
  width: 325px;
  @media (max-width: 1050px) {
    margin-left: 12px;
  }
`

const Logo = styled.div`
  color: rgba(44, 44, 44, 0.96);
  padding-top: 10px;
  font-size: 1.94em;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 0.007em;
  font-family: Raleway, "Times new roman", serif;
  @media (max-width: 1000px) {
    font-size: 1.9em;
  }
`

const SubTitle = styled.div`
  font-size: 1.05em;
  letter-spacing: 0.016em;
  padding-top: 3px;
  color: #5e6677;
`

const Ul = styled.ul`
  display: flex;
  align-items: stretch;
  list-style: none;
  padding: 0px;
  margin: 2em 0px 0px;
  @media (max-width: 1050px) {
    display: none;
    background-color: white;
    margin-top: 0;
    padding-top: 12px;
    height: 90vh;
  }
  .toggled & {
    display: block;
  }
`

const Li = styled.li`
  margin-left: 1.6em;
  font-size: 1.08em;
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
  :hover,
  :focus {
    color: #d6af68;
  }
  @media (max-width: 1050px) {
    padding: 0.5em 0 0.5em;
    font-weight: 400;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  :hover,
  :focus {
    color: #d6af68;
  }
`
